import './HtmlContent.scss';

import cx from 'classnames';
import React, { useMemo } from 'react';

/**
 * Renders HTML in a div
 */
const HtmlContent = ({ content, className }: ContentProps): JSX.Element => {
  const classes = useMemo(() => cx('htmlContent', className), [className]);
  return typeof content === 'string' ? (
    <div className={classes} dangerouslySetInnerHTML={{ __html: content }} />
  ) : (
    <div className={classes}>{content}</div>
  );
};

interface ContentProps {
  content: React.ReactNode;
  className?: string;
}

export default HtmlContent;
