import './blog-post.scss';

import { graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';

import HtmlContent from '~/components/HtmlContent';
import Layout from '~/components/Layout';
import ShareButtons from '~/components/ShareButtons';
import { BlogPostByIdQuery } from '~types/graphql-types';

interface BlogPostTemplateProps {
  content: React.ReactNode;
  description: string;
  helmet?: React.ReactNode;
  tags?: string[];
  title: string;
  url: string;
}

/**
 * Blog post template
 */
export const BlogPostTemplate = ({
  content,
  description,
  helmet,
  tags,
  title,
  url,
}: BlogPostTemplateProps): JSX.Element => {
  return (
    <Layout>
      <section className="container">
        {helmet || ''}
        <div className="content">
          <div className="post">
            <h1 className="post-title">{title}</h1>
            <p>{description}</p>
            <HtmlContent content={content} />
            {tags && tags.length ? (
              <div className="post-tags">
                <h4>Etiquetas:</h4>
                <ul className="post-tagList">
                  {tags.map(tag => (
                    <li className="post-tag" key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="post-social">
              <h4>Compartir:</h4>
              <ShareButtons title={title} tags={tags} url={url} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

interface BlogPostProps {
  data: BlogPostByIdQuery;
}

/**
 * Maps props from query to template
 */
const BlogPostTemplateContainer = ({ data }: BlogPostProps): JSX.Element | null => {
  const { markdownRemark: post } = data;
  if (!post) {
    throw new Error('Post data not found');
  }
  const { fields, frontmatter } = post;
  if (
    !frontmatter ||
    !fields ||
    !post.html ||
    !frontmatter.description ||
    !frontmatter.title ||
    !fields.slug
  ) {
    throw new Error('Missing required data');
  }
  const helmet = <Helmet title={`${frontmatter.title} | Blog`} />;
  const props: BlogPostTemplateProps = {
    content: post.html,
    description: frontmatter.description,
    helmet,
    title: frontmatter.title,
    url: fields.slug,
  };

  return <BlogPostTemplate {...props} />;
};

export default BlogPostTemplateContainer;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
